import React, { useState, createContext } from 'react'
import styled from 'styled-components'
import CountryFilters from '../CompareFilters/countries/CountryFilters'
import PolicyFilters from '../CompareFilters/policies/PolicyFilters'
import ComparisonChart from './ComparisonChart/ComparisonChart'
import StatusSelector from './StatusSelector'
import AxisSelector from './AxisSelector'
import Legend from './Legend/Legend'
import FilterFrame from '../CompareFilters/FilterFrame'

const ChartFrame = styled.section`
  display: flex;
`
const ControlsFrame = styled.div`
  display: flex;
  flex-direction: column;
`

export const ComparisonContext = createContext()

const ComparisonTool = ({ policies, regions, pepfar }) => {
  const [axisType, setAxisType] = useState('percent')
  const [adoptionCondition, setAdoptionCondition] = useState('adopted')

  const allCountries = Object.values(Object.values(policies)[0].adoption)
  const [selectedCountries, setSelectedCountries] = useState(
    new Set(allCountries.map(c => c.place_id))
  )

  const [selectedPolicies, setSelectedPolicies] = useState([[], [], []])

  const contextValue = {
    pepfar,
    regions,
    policies,
    axisType,
    adoptionCondition,
    allCountries,
    selectedCountries,
    setSelectedCountries,
    selectedPolicies,
    setSelectedPolicies,
  }

  // console.log(policies)

  return (
    <ComparisonContext.Provider value={contextValue}>
      <FilterFrame contextValue={contextValue}>
        <PolicyFilters />
        <CountryFilters />
      </FilterFrame>
      <ChartFrame>
        <ComparisonChart />
        <ControlsFrame>
          <Legend {...{ adoptionCondition }} />
          <StatusSelector {...{ setAdoptionCondition }} />
          <AxisSelector {...{ setAxisType }} />
        </ControlsFrame>
      </ChartFrame>
    </ComparisonContext.Provider>
  )
}

export default ComparisonTool
