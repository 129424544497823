import React, { useContext } from 'react'
import styled from 'styled-components'
import PolicySelector from './PolicySelector'

import { ComparisonContext } from '../FilterFrame'

const FilterFrame = styled.div`
  display: flex;
  flex-direction: column;
`
const FilterColumns = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`

const PolicyFilters = () => {
  const { selectedPolicies } = useContext(ComparisonContext)
  return (
    <FilterFrame>
      <h4>Choose policy or policy combination</h4>
      <FilterColumns>
        {selectedPolicies.map((_, index) => (
          <PolicySelector key={index} index={index} />
        ))}
      </FilterColumns>
    </FilterFrame>
  )
}

export default PolicyFilters
