import React from 'react'
import styled from 'styled-components'

import Tooltip from './Tooltip'
const labelFontSize = 6
const labelPad = 10
const lineHeight = 1.5

const Label = styled.text`
  font-size: ${labelFontSize}px;
  font-weight: 600;
  text-anchor: middle;
  fill: ${props => props.theme.colorBlack};
`
const LightLabel = styled.tspan`
  fill: ${({ theme }) => theme.colorMiddleGray};
  font-weight: 400;
`
const Path = styled.path`
  transition: 250ms ease;
  cursor: pointer;
  fill: ${props =>
    ({
      null: 'url(#nullPattern)',
      0: props.theme.colorVeryFew,
      0.25: `url(#pattern_0)`,
      0.75: `url(#pattern_1)`,
      1: props.theme.colorMost,
    }[props.status])};
  stroke-width: 0.25;
  stroke: ${props => props.theme.colorMiddleGray};
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 0.25px solid blue;
  }
`
const Italic = styled.tspan`
  font-style: italic;
  fill: ${({ theme }) => theme.colorMiddleGray};
`
const NoneSelectedPath = styled.path`
  stroke-width: 0.25;
  fill: ${({ theme }) => theme.colorEvenLighterGray};
`

const Bar = ({ bar, index, dim, axisType }) => {
  const layers = [
    { status: 0.25, countries: {} },
    { status: 0.75, countries: {} },
    { status: 1, countries: {} },
    { status: 0, countries: {} },
  ]

  bar.layers.forEach(layer => {
    layers[layers.findIndex(l => l.status === layer.status)] = layer
  })

  let barEmpty = !bar.names[0]

  // console.log(bar.layers)
  const addZeroLabel = bar.layers.every(
    layer => Object.keys(layer.countries).length === 0
  )

  return (
    <g
      style={{
        transform: `translateX(${
          dim.axes.x.scale(index) - dim.barWidth / 2
        }px)`,
      }}
    >
      <g style={{ transform: `translateX(${dim.barWidth / 2}px)` }}>
        <Label x={0} y={dim.axes.y.start + labelPad}>
          <tspan>
            {bar.names[0] ? (
              bar.names[0]
            ) : (
              <Italic>Select polices above</Italic>
            )}
          </tspan>
          {bar.names[1] && (
            <tspan x={0} dy={labelFontSize * lineHeight}>
              <LightLabel>and</LightLabel> {bar.names[1]}
            </tspan>
          )}
          {bar.names[2] && (
            <tspan x={0} dy={labelFontSize * lineHeight}>
              <LightLabel>and</LightLabel> {bar.names[2]}
            </tspan>
          )}
        </Label>
      </g>
      {barEmpty && (
        <NoneSelectedPath
          d={`
            M 0,${dim.axes.y.start}
            L 0,${dim.axes.y.end}
            L ${dim.barWidth},${dim.axes.y.end}
            L ${dim.barWidth},${dim.axes.y.start}
          `.replace(/\s+/g, '')}
        />
      )}
      {!barEmpty &&
        layers.map(({ status, countries }) => (
          <React.Fragment key={status}>
            <Tooltip {...{ index, bar }}>
              <Path
                status={status}
                d={`
            M 0,${dim.axes.y.scale(0)}
            L 0,${Math.min(
              dim.axes.y.scale(Object.keys(countries).length),
              dim.axes.y.scale(0) - 0.5
            )}
            L ${dim.barWidth},${Math.min(
                  dim.axes.y.scale(Object.keys(countries).length),
                  dim.axes.y.scale(0) - 0.5
                )}
            L ${dim.barWidth},${dim.axes.y.scale(0)}
          `.replace(/\s+/g, '')}
              />
            </Tooltip>
            {addZeroLabel && (
              <Label
                style={{
                  transform: `translate(${dim.barWidth / 2}px, ${
                    dim.axes.y.scale(0) - 5
                  }px)`,
                }}
              >
                0{axisType === 'percent' && '%'}
              </Label>
            )}
          </React.Fragment>
        ))}
    </g>
  )
}
// ${console.log(Object.keys(layer.countries).length)}

export default Bar
