import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import axios from 'axios'

import GlobalVacuumChart from './GlobalVacuumChart'
import Legend from './Legend'

import Paginator from '../policypage/VacuumTable/Paginator'
import FilterFrame from '../CompareFilters/FilterFrame'
import CountryFilters from '../CompareFilters/countries/CountryFilters'
import PolicyGroups from '../CompareFilters/policyGroups/PolicyGroups'

const API_URL = process.env.GATSBY_METRICS_API_URL

const TableScroller = styled.div`
  overflow-x: scroll;
  width: 100%;
`
const TableMinWidth = styled.div`
  min-width: 1100px;
`
const TableHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin-bottom: 5rem;
`

// fetch the epi data and merge it in to the table data
// if epi metrics get selectable at some point this should
// be refactored into that component
const getEpiMetrics = async (setData, selectedEpiMetrics, epiMetrics) => {
  const today = new Date().toISOString().split('T')[0]

  selectedEpiMetrics.forEach(async ({ key, name }) => {
    const metricID = epiMetrics.find(m => m.name === name).value

    const metricData = await axios(`${API_URL}/observations`, {
      params: {
        temporal_resolution: 'yearly',
        spatial_resolution: 'country',
        metric_id: metricID,
        lag_allowed: 1000,
        start: today,
        end: today,
      },
    })

    const dataByCountry = {}
    metricData.data.data.forEach(
      obs => (dataByCountry[obs.place_iso] = obs.value)
    )

    setData(prev => {
      const mergedData = prev.map(row => ({
        ...row,
        [key]: dataByCountry[row.place_id],
      }))

      mergedData.sort((a, b) => a.place_name.localeCompare(b.place_name))
      setData([...mergedData])
    })
  })
}

// merge the selected policies into the overall table data
const mergeSelectedPolicies = (setData, selectedPolicies, policies) => {
  setData(prev => {
    if (!prev) return undefined

    const newRows = []
    prev.forEach((row, index) => {
      newRows[index] = row
      const selectedArr = [...selectedPolicies]
      selectedArr.forEach(abbr => {
        newRows[index][abbr] = policies[abbr].adoption[row.place_id].adoption
      })
    })

    return [...newRows]
  })
}

const GlobalVacuumChartSection = ({
  epiMetrics,
  policies,
  regions,
  pepfar,
}) => {
  // data is the overall state of all the client-side data
  const [data, setData] = useState(
    Object.values(Object.values(policies)[0].adoption).map(adoption => ({
      place_name: adoption.place_name,
      place_id: adoption.place_id,
    }))
  )

  // this will probably be refactored to the selector components
  const [selectedPolicies, setSelectedPolicies] = useState(
    new Set(['CT1', 'CT2', 'CT3', 'CT4', 'CT5', 'CT6', 'CT7', 'CT8'])
  )

  const allCountries = Object.values(Object.values(policies)[0].adoption)
  const [selectedCountries, setSelectedCountries] = useState(
    new Set(allCountries.map(c => c.place_id))
  )

  // putting this in state leaves room for epi selection
  // controls if that's needed later and lets us
  // keep all these working on the same pattern
  const [selectedEpiMetrics] = useState([
    {
      key: 'incidence',
      name: 'HIV Incidence',
      label: 'New HIV Infections',
      unit: '',
      info: (
        <>
          This incidence includes new HIV infections, for individuals of all
          ages, in 2021.
          <br />
          <br />
          Incidence data are provided by UNAIDS based on data reported in the
          Global AIDS Update 2023 (covering through 2022).{' '}
          <a href="https://www.unaids.org/en/resources/documents/2023/global-aids-update-2023">
            Full report and methodology available here.
          </a>
        </>
      ),
    },
    {
      key: 'art',
      name: 'HIV patients on ART',
      label: 'HIV patients on ART',
      unit: '%',
      info: (
        <>
          The ART treatment percentage represents the proportion of people who
          are receiving ART treatment among all those living with HIV.
          <br />
          <br />
          ART treatment data are provided by UNAIDS based on data reported in
          the Global AIDS Update 2023 (covering through 2022).{' '}
          <a href="https://www.unaids.org/en/resources/documents/2023/global-aids-update-2023">
            Full report and methodology available here.
          </a>
        </>
      ),
    },
  ])

  const [filteredData, setFilteredData] = useState(data)

  // tableData is what's actually being displayed
  // so it's where pagination happens
  const [tableData, setTableData] = useState()

  // Effect for API requests to get epi data
  // This only needs to run at component mount
  useEffect(() => {
    getEpiMetrics(setData, selectedEpiMetrics, epiMetrics)
  }, [selectedEpiMetrics, epiMetrics])

  // Effect to merge in policies
  // runs whenever policy selection changes
  useEffect(() => {
    mergeSelectedPolicies(setData, selectedPolicies, policies)
  }, [selectedPolicies])

  useEffect(() => {
    setFilteredData(
      data && data.filter(row => selectedCountries.has(row.place_id))
    )
  }, [data, selectedCountries])

  const contextValue = {
    pepfar,
    regions,
    policies,
    allCountries,
    selectedCountries,
    setSelectedCountries,
    selectedPolicies,
    setSelectedPolicies,
  }

  return (
    <TableHolder>
      <FilterFrame {...{ contextValue }}>
        <PolicyGroups />
        <CountryFilters />
      </FilterFrame>
      <Legend />
      <TableScroller>
        <TableMinWidth>
          {tableData && (
            <GlobalVacuumChart
              {...{
                setData,
                tableData,
                policies,
                selectedPolicies,
                selectedEpiMetrics,
              }}
            />
          )}
          <Paginator data={filteredData} {...{ setTableData }} />
        </TableMinWidth>
      </TableScroller>
    </TableHolder>
  )
}

export default GlobalVacuumChartSection
