import React from 'react'
import styled from 'styled-components'

import PolicyAbbr from '../policypage/PolicyAbbr'

import * as FMT from '../FormatAndDisplay/FormatAndDisplay'
import InfoBubble from '../InfoBubble/InfoBubble'

import Table from '../policypage/VacuumTable/Table'
import Thead from '../policypage/VacuumTable/Thead'

import AngledHeader from '../policypage/VacuumTable/headers/AngledHeader'
import Header from '../policypage/VacuumTable/headers/Header'

import FlagLink from '../policypage/VacuumTable/columns/FlagLink'
import AdoptionStatus from '../policypage/VacuumTable/columns/AdoptionStatus'
import BlueColumn from '../policypage/VacuumTable/columns/BlueColumn'

import sortNumeric from '../policypage/VacuumTable/sorting/sortNumeric'
import sortString from '../policypage/VacuumTable/sorting/sortString'

// const StyledTable = styled(Table)`
/*margin-top: 5rem;*/
// `
const StyledHeader = styled(Header)`
  width: 20%;
`
const DataNotAvailable = styled.span`
  font-style: italic;
  color: ${({ theme }) => theme.colorMiddleGray};
`

const AdoptionTable = ({
  setData,
  tableData,
  policies,
  selectedPolicies,
  selectedEpiMetrics,
}) => (
  <Table style={{ paddingTop: selectedPolicies.size < 4 ? '15px' : '16rem' }}>
    <Thead>
      <tr>
        <StyledHeader
          sortFunc={sortString({
            field: 'place_name',
            stateSetter: setData,
          })}
          name="country"
        >
          Country
        </StyledHeader>
        {[...selectedPolicies].map(abbr => (
          <AngledHeader
            key={abbr}
            name={abbr}
            sortFunc={sortNumeric({
              field: abbr,
              stateSetter: setData,
            })}
            angle={selectedPolicies.size < 4 ? 0 : 60}
          >
            <PolicyAbbr>{abbr}</PolicyAbbr>
            <span>{policies[abbr].name}</span>
          </AngledHeader>
        ))}
        {selectedEpiMetrics.map(metric => (
          <Header
            key={metric.key}
            name={metric.key}
            sortFunc={sortNumeric({
              field: metric.key,
              stateSetter: setData,
            })}
          >
            {metric.label} <InfoBubble>{metric.info}</InfoBubble>
          </Header>
        ))}
      </tr>
    </Thead>
    <tbody>
      {tableData &&
        tableData.map(row => (
          <tr key={row.place_id}>
            <FlagLink iso2={row.place_id}>{row.place_name}</FlagLink>
            {[...selectedPolicies].map(abbr => (
              <AdoptionStatus
                key={abbr}
                label={selectedPolicies.size < 4}
                status={row[abbr]}
              />
            ))}
            {selectedEpiMetrics.map(metric => (
              <BlueColumn key={metric.key}>
                {row[metric.key] ? (
                  <>
                    <FMT.ExactNumber>{row[metric.key]}</FMT.ExactNumber>
                    {metric.unit}
                  </>
                ) : (
                  <DataNotAvailable>N/A</DataNotAvailable>
                )}
              </BlueColumn>
            ))}
          </tr>
        ))}
    </tbody>
  </Table>
)

export default AdoptionTable
