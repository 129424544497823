import React from 'react'
import styled from 'styled-components'

const Path = styled.path`
  stroke-width: 4;
  stroke: ${({ status, theme }) =>
    ({
      0: theme.colorVeryFew,
      1: theme.colorMost,
    }[status])};
`

const Background = styled.rect`
  fill: ${props => props.theme.colorSome};
  stroke: none;
`

const Patterns = () => (
  <>
    <pattern
      id={'pattern_0'}
      width="10"
      height="10"
      patternUnits="userSpaceOnUse"
      patternTransform={`scale(${0.25}) rotate(155)`}
    >
      <Background x={0} y={0} height={10} width={10} />
      <Path status={0} d="M0 10h20z" />
    </pattern>
    <pattern
      id={'pattern_1'}
      width="10"
      height="10"
      patternUnits="userSpaceOnUse"
      patternTransform={`scale(${0.25}) rotate(155)`}
    >
      <Background x={0} y={0} height={10} width={10} />
      <Path status={1} d="M0 10h20z" />
    </pattern>
  </>
)

export default Patterns
