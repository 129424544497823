import * as d3 from 'd3/dist/d3.min'

const getDimensions = (bars, axisType, selectedCountries) => {
  const dim = {
    width: 500,
    height: 220,
    plotGap: 100,

    barWidth: 42,
    barXPadding: 80,
  }

  dim.padding = {
    top: 4,
    bottom: 50,
    left: 35,
    right: 20,
  }

  dim.axes = {
    x: {
      start: dim.padding.left,
      end: dim.width - dim.padding.right,
      length: dim.width - dim.padding.right - dim.padding.left,
    },
    y: {
      start: dim.height - dim.padding.bottom,
      end: dim.padding.top,
      length: dim.height - dim.padding.bottom - dim.padding.top,
    },
  }

  if (axisType === 'count') {
    const maxBar = bars.reduce((max, bar) => {
      return Math.max(
        ...bar.layers.map(l => Object.keys(l.countries).length),
        max
      )
    }, 0)

    dim.plotMax =
      maxBar !== 0
        ? Math.ceil((maxBar * 1.05) / 10) * 10
        : selectedCountries.size
  } else {
    dim.plotMax = selectedCountries.size
  }

  dim.axes.y.scale = d3
    .scaleLinear()
    .domain([0, dim.plotMax])
    .range([dim.axes.y.start, dim.axes.y.end])

  dim.axes.x.scale = d3
    .scaleLinear()
    .domain([0, bars.length - 1])
    .range([
      dim.axes.x.start + dim.barXPadding,
      dim.axes.x.end - dim.barXPadding,
    ])

  return dim
}

export default getDimensions
