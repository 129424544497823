const comparisons = adoptionCondition => {
  switch (adoptionCondition) {
    case 'adopted':
      return [{ status: 1, compare: policy => policy.adoption === 1 }]

    case 'adoptedAndPartial':
      return [
        { status: 0.75, compare: policy => policy.adoption >= 0.5 },
        { status: 1, compare: policy => policy.adoption === 1 },
      ]

    case 'partialAndNot':
      return [
        { status: 0.25, compare: policy => policy.adoption <= 0.5 },
        { status: 0, compare: policy => policy.adoption === 0 },
      ]

    case 'not':
      return [{ status: 0, compare: policy => policy.adoption === 0 }]
  }
}

export default comparisons
