import React from 'react'
import styled from 'styled-components'

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`
const LabelText = styled.span`
  display: block;
  margin-left: 0.5em;
  font-weight: normal;
  font-size: 16px;
`
const RadioLabel = styled.label`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
`

const StatusSelector = ({ setAxisType }) => (
  <RadioContainer onChange={e => setAxisType(e.target.value)}>
    <p style={{ marginBottom: 5 }}>
      <strong style={{ fontSize: 16 }}>View by</strong>
    </p>
    <RadioLabel>
      <input name="axisType" type="radio" value="percent" defaultChecked />
      <LabelText>Percent of selected countries</LabelText>
    </RadioLabel>
    <RadioLabel>
      <input name="axisType" type="radio" value="count" />
      <LabelText>Number of countries</LabelText>
    </RadioLabel>
  </RadioContainer>
)

export default StatusSelector
