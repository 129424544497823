import React from 'react'

// Formatting components to display numbers in a standardized way.
// These components should not add any nodes besides TextNodes to the dom
// so that they do not interfere with CSS styling in components where
// they are used.

// Add thousands place commas to number
export const ExactNumber = props => {
  const formatted = props.children
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  return <>{formatted}</>
}

// rounds to the nearest int for numbers less than 10,
// nearest 10 for numbers less than 100, and
// nearest hundred for numbers larger than that.
export const ModeledNumber = props => {
  const integer = parseInt(props.children)
  let formatted

  if (integer <= 10) {
    formatted = integer.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  } else if (integer <= 100) {
    formatted = (Math.round(integer / 10) * 10)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  } else {
    formatted = (Math.round(integer / 100) * 100)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  return <>{formatted}</>
}

// format date as May 29, 2020
export const LocalDate = props => {
  const formatted = new Date(props.children).toLocaleString('default', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  })

  return <>{formatted}</>
}

export const Month = props => {
  const formatted = new Date(props.children).toLocaleString('default', {
    month: 'short',
    timeZone: 'UTC',
  })

  return <>{formatted}</>
}

// convert decimal to percent
export const Percent = props => {
  const formatted = Math.round(props.children * 100) + '%'
  return <>{formatted}</>
}

//

export const Sources = props => {
  if (props.data) {
    const sources = props.data.flat().map(point => point.source)
    const sourcesL1 = props.lag
      ? props.data.flat().map(point => point.sourceL1)
      : []
    const allSources = [...new Set([...sources, ...sourcesL1])]
    return (
      <>
        {allSources.map((source, index) => {
          let sep =
            index + 2 > allSources.length
              ? ' '
              : index + 2 < allSources.length
              ? ', '
              : ' and '
          return <span key={index}>{source + sep}</span>
        })}
      </>
    )
  } else {
    return null
  }
}
