import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import SelectedFilters from '../SelectedFilters'

import TypeaheadResult from '../../policypage/TypeaheadControl/TypeaheadResult'
import TypeaheadControl from '../../policypage/TypeaheadControl/TypeaheadControl'

import { ComparisonContext } from '../FilterFrame'

const maxItems = 11

const ControlsHolder = styled.div`
  margin-top: 0.5em;
  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`
const ControlContainer = styled.div`
  flex-basis: calc(33.33% - 8px);
  margin-right: 10px;
  font-size: 17px;

  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`
const Header = styled.header`
  display: flex;
  align-items: baseline;
  margin-top: 15px;
`
const Italic = styled.span`
  font-style: italic;
`

const policyGroupLabels = {
  CT: 'Clinical and treatment',
  TP: 'Testing and prevention',
  S: 'Structural',
  HS: 'Health Systems',
}

const PolicyGroups = () => {
  const { policies, setSelectedPolicies, selectedPolicies } = useContext(
    ComparisonContext
  )

  const policyGroups = {}
  Object.keys(policies).map(key => {
    const category = key.match(/[A-Z]*[^0-9]/)[0]
    policyGroups[category] = {
      key: category,
      label: policyGroupLabels[category],
      type: 'Category',
      values: policyGroups[category]
        ? policyGroups[category].values.add(key)
        : new Set([key]),
    }
  })

  const [selected, setSelected] = useState([
    {
      key: 'CT',
      type: 'Default',
      label: policyGroupLabels['CT'],
      values: policyGroups['CT'].values,
    },
  ])

  const typeaheadPolicies = Object.entries(policies)
    .map(([abbr, { name }]) => ({
      type: 'Policy',
      key: abbr,
      label: `${abbr} - ${name}`,
      values: new Set([abbr]),
    }))
    .filter(p => !selected.map(s => s.key).includes(p.key))
    .sort((a, b) => a.label.localeCompare(b.label))

  const typeaheadGroups = [...Object.values(policyGroups)]
    .filter(p => !selected.map(s => s.key).includes(p.key))
    .sort((a, b) => a.label.localeCompare(b.label))

  const addFilter = item => {
    if (item) {
      setSelected(prev => {
        if (item.values.size + selectedPolicies.size <= maxItems)
          return [...prev.filter(s => s.type !== 'Default'), { ...item }]
        return [{ ...item }]
      })
      setSelectedPolicies(prev => {
        if (
          selected[0].type === 'Default' ||
          prev.size + item.values.size > maxItems
        )
          return new Set([...item.values])
        return new Set([...prev, ...item.values])
      })
    }
  }

  const removeFilter = item => {
    setSelected(prev => {
      prev = [...prev.filter(s => s.key !== item.key)]
      if (prev.length === 0)
        prev = [
          {
            key: 'CT',
            type: 'Default',
            label: policyGroupLabels['CT'],
            values: policyGroups['CT'].values,
          },
        ]
      return prev
    })

    setSelectedPolicies(prev => {
      const removeItems = [...item.values]
      removeItems.forEach(key => prev.delete(key))
      if (prev.size === 0) prev = policyGroups['CT'].values
      return new Set(prev)
    })
  }

  const removeAll = () => {
    setSelected([
      {
        type: 'Default',
        label: policyGroupLabels['CT'],
        values: policyGroups['CT'].values,
      },
    ])
    setSelectedPolicies(new Set(policyGroups['CT'].values))
  }

  return (
    <>
      <Header>
        <h4>Choose policies</h4>
      </Header>
      <ControlsHolder>
        <ControlContainer>
          <TypeaheadControl
            value={''}
            disabled={selectedPolicies.size >= maxItems}
            placeholder={
              selectedPolicies.size < maxItems
                ? 'Choose policy'
                : 'Remove a policy to select another'
            }
            items={typeaheadPolicies}
            onChange={addFilter}
            renderItem={item => <TypeaheadResult>{item.label}</TypeaheadResult>}
          />
        </ControlContainer>
        <ControlContainer>
          <TypeaheadControl
            value={''}
            disabled={selectedPolicies.size >= maxItems}
            placeholder={
              selectedPolicies.size < maxItems
                ? 'Choose category'
                : 'Remove a policy to select another'
            }
            items={typeaheadGroups}
            onChange={addFilter}
            renderItem={item => <TypeaheadResult>{item.label}</TypeaheadResult>}
          />
        </ControlContainer>
      </ControlsHolder>
      <SelectedFilters
        type={'policies'}
        countOverride={selectedPolicies.size}
        items={selected}
        onClick={removeFilter}
        onClear={removeAll}
      />
    </>
  )
}

export default PolicyGroups
