import React, { useContext } from 'react'
import styled from 'styled-components'

import Axes from './Axes'
import Bar from './Bar'
import Patterns from './Patterns'

import { ComparisonContext } from '../ComparisonTool'

import getDimensions from './getDimensions'
import intersect from '../intersect'
import comparisons from '../comparisons'

const PlotContainer = styled.div`
  width: 100%;
  margin-top: 3em;
  margin-bottom: 3rem;
`
const Svg = styled.svg`
  overflow: visible;
`

const ComparisonChart = () => {
  const {
    axisType,
    selectedPolicies,
    adoptionCondition,
    selectedCountries,
  } = useContext(ComparisonContext)

  // bars structure:
  // [
  //   { names: [], layers: [{ status: 0, countries: {} }] },
  //   { names: [], layers: [{ status: 0, countries: {} }] },
  //   { names: [], layers: [{ status: 0, countries: {} }] },
  // ]

  const bars = selectedPolicies.map(selected => ({
    names: selected.map(s => s.label),
    layers: comparisons(adoptionCondition).map(comparison => ({
      status: comparison.status,
      countries: intersect(
        [...selected.map(s => s.adoption)],
        selectedCountries,
        comparison.compare
      ),
    })),
  }))

  const dim = getDimensions(bars, axisType, selectedCountries)

  // const axisLabel = {
  //   adopted: 'Policies adopted',
  //   adoptedAndPartial: 'Policies adopted and partially adopted',
  //   partialAndNot: 'Partially adopted and not adopted',
  //   not: 'Policies not adopted',
  // }[adoptionCondition]

  return (
    <PlotContainer>
      <Svg viewBox={`0 0 ${dim.width} ${dim.height}`}>
        <Patterns />
        {bars.map((bar, index) => (
          <Bar key={index} {...{ bar, index, dim, axisType }} />
        ))}
        <Axes
          x={dim.axes.x}
          y={dim.axes.y}
          plotMax={dim.plotMax}
          axisType={axisType}
          xLabel={'Policy adoption'}
        />
      </Svg>
    </PlotContainer>
  )
}

export default ComparisonChart
