import React, { useState } from 'react'
import styled from 'styled-components'

import PolicyLayout from '../components/policypage/PolicyLayout/PolicyLayout'
import darkGlobeIcon from '../assets/icons/darkGlobe.svg'

import ComparisonTool from '../components/globalCompare/ComparisonTool'
import GlobalVacuumChartSection from '../components/GlobalVacuumChartSection/GlobalVacuumChartSection'
import ExpandingSection from '../components/policypage/ExpandingSection/ExpandingSection'
import ExpandIndicator from '../components/policypage/DetailTable/ExpandIndicator'

const Main = styled.main`
  margin: auto;
  padding: 20px;
  max-width: 1440px;
`
const IntroSection = styled.section`
  border-top: 1px solid ${({ theme }) => theme.colorPrimaryBlue3};
  padding-top: 0.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`
const IntroParagraph = styled.p`
  padding-top: 0.5em;
  font-size: 20px;
  margin-left: 5rem;
  margin-right: 5rem;
`
const Header = styled.header`
  display: flex;
  align-items: center;
`
const GlobeImg = styled.img`
  margin-top: 20px;
  margin-bottom: 10px;
  margin-right: 1rem;
`
const Italics = styled.span`
  font-style: italic;
`
const DividingLine = styled.span`
  border-top: 1px solid ${({ theme }) => theme.colorPrimaryBlue3};
  display: block;
  width: 100%;
  margin-bottom: 2rem;
  /*padding-top: 2rem;*/
`
const ExpandHeader = styled.div`
  margin-top: 1rem;
  margin-left: 5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: baseline;
  font-size: 18px;
  font-weight: 600;
`

const GlobalCompareTemplate = props => {
  const [firstExpanded, setFirstExpanded] = useState(false)
  const [secondExpanded, setSecondExpanded] = useState(false)

  return (
    <PolicyLayout>
      {/* {console.log(props)} */}
      <Main>
        <Header>
          <GlobeImg src={darkGlobeIcon} />
          <h1>Global policy comparison tools</h1>
        </Header>
        <IntroSection>
          <IntroParagraph>
            <h2>Comparing policy adoption</h2>
            The interactive bar chart below allows you to compare adoption rates
            for different policies.
          </IntroParagraph>
          <ExpandingSection
            open={firstExpanded}
            onOpen={() => setFirstExpanded(true)}
            onClose={() => setFirstExpanded(false)}
          >
            <ExpandHeader>
              <ExpandIndicator open={firstExpanded} />
              {firstExpanded ? 'Collapse' : 'Expand'} instructions
            </ExpandHeader>
            <IntroParagraph>
              First, use the dropdown menus in the grey box to choose which
              policies you want to compare. You can compare individual policies
              (up to 3), or combinations of policies (with up to 3 policies per
              combination, for a total of 9 policies), or a mix of the two.
              Next, you have the option to click on the “choose countries” arrow
              and use the dropdown menus to select which countries, regions, or
              groups of countries you want to view (add as many as you would
              like). Third, use the radio buttons on the right side of the
              graph, to select whether you want to view data on the countries
              that have fully adopted, fully or partially adopted, partially
              adopted or not adopted, or not adopted your selected policies.
              (Keep in mind that the partially adopted countries are the same
              countries in either view.) If you are viewing a single policy,
              “partially adopted” means that a country has partially adopted
              that one policy. If you’re viewing a combination of policies,
              “partially adopted”means that a country has partially adopted one
              or more of the policies in your combination and/or that it has
              adopted some of the policies in your combination but not others.
              Lastly, you can decide whether you’d like to view the results by
              number of countries or percentage of countries.
            </IntroParagraph>
          </ExpandingSection>
          <IntroParagraph>
            <Italics>
              Policy adoption status shows the most recent data.
            </Italics>
          </IntroParagraph>
        </IntroSection>

        <ComparisonTool
          policies={props.pageContext.policies}
          regions={props.pageContext.regions}
          pepfar={props.pageContext.pepfar}
        />

        <DividingLine>
          <IntroParagraph>
            <h2>Global policy comparison</h2>
            The table below allows you to compare the policy adoption status
            across the policies and countries of your choice.
          </IntroParagraph>
          <ExpandingSection
            open={secondExpanded}
            onOpen={() => setSecondExpanded(true)}
            onClose={() => setSecondExpanded(false)}
          >
            <ExpandHeader>
              <ExpandIndicator open={secondExpanded} />
              {firstExpanded ? 'Collapse' : 'Expand'} instructions
            </ExpandHeader>
            <IntroParagraph>
              First, use the dropdown menus in the grey box to select which
              policies you want to compare. You can choose up to 11 individual
              policies or select one of the policy categories and view all the
              policies in that category. Next, you have the option to click on
              the “choose countries” arrow and use the dropdown menus to select
              which countries, regions, or groups of countries you want to view
              (add as many as you’d like). Lastly, click on the arrows at the
              top of each column header to sort the data by that indicator.
            </IntroParagraph>
          </ExpandingSection>
          <IntroParagraph>
            <Italics>
              Policy adoption status shows the most recent data.
            </Italics>
          </IntroParagraph>
        </DividingLine>
        <GlobalVacuumChartSection
          policies={props.pageContext.policies}
          epiMetrics={props.pageContext.epiMetrics}
          mostRecent={props.pageContext.policies}
          regions={props.pageContext.regions}
          pepfar={props.pageContext.pepfar}
        />
      </Main>
    </PolicyLayout>
  )
}

export default GlobalCompareTemplate
