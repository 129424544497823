// return the object that is the
// intersection of all objects in
// the array, according to the
// comparison function, with values
// taken from the first object.

const intersect = (objArr, countries, conditional) => {
  if (objArr.length === 0) return {}

  const intersection = {}
  for (const key of Object.keys(objArr[0])) {
    if (
      objArr.every(
        obj =>
          obj[key] &&
          obj[key].adoption !== null &&
          countries.has(key) &&
          conditional(obj[key])
      )
    )
      intersection[key] = objArr[0][key]
  }
  return intersection
}

export default intersect
