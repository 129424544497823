import React from 'react'
import styled from 'styled-components'
import AdoptionStatusBlock from '../policypage/AdoptionStatusBlock'
import AdoptionStatusTerm from '../policypage/AdoptionStatusTerm'

const LegendContainer = styled.section`
  display: flex;
  /*width: 60%;*/
  margin-left: auto;
  margin-top: 15px;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 15px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`
const Label = styled.span`
  display: block;
  font-size: 16px;
  margin-right: 15px;
  @media screen and (max-width: 900px) {
    text-align: right;
  }
`
const StyledAdoptionStatusBlock = styled(AdoptionStatusBlock)`
  margin-left: 10px;
  margin-top: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
`

const Legend = () => (
  <LegendContainer>
    <Label>Policy adoption scale</Label>
    <StyledAdoptionStatusBlock status={1}>
      <AdoptionStatusTerm uppercase status={1} />
    </StyledAdoptionStatusBlock>
    <StyledAdoptionStatusBlock status={0.5}>
      <AdoptionStatusTerm uppercase status={0.5} />
    </StyledAdoptionStatusBlock>
    <StyledAdoptionStatusBlock status={0}>
      <AdoptionStatusTerm uppercase status={0} />
    </StyledAdoptionStatusBlock>
    <StyledAdoptionStatusBlock status={null}>
      <AdoptionStatusTerm uppercase status={null} />
    </StyledAdoptionStatusBlock>
  </LegendContainer>
)

export default Legend
