import React, { useContext } from 'react'
import styled from 'styled-components'

import SelectedFilters from '../SelectedFilters'
import TypeaheadControl from '../../policypage/TypeaheadControl/TypeaheadControl'
import TypeaheadResult from '../../policypage/TypeaheadControl/TypeaheadResult'

import { ComparisonContext } from '../FilterFrame'

const Container = styled.section`
  flex: 1 1 0;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  font-size: 17px;

  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`
const TypeaheadLabel = styled.label`
  width: 100%;
`

const PolicySelector = ({ index }) => {
  const { policies, selectedPolicies, setSelectedPolicies } = useContext(
    ComparisonContext
  )

  const selectedKeys = selectedPolicies[index].map(s => s.key)
  const typeaheadPolicies = Object.entries(policies)
    .map(([abbr, policy]) => ({
      key: abbr,
      label: `${abbr} - ${policy.name}`,
      adoption: policy.adoption,
    }))
    .filter(p => !selectedKeys.includes(p.key))

  const addPolicy = item =>
    item &&
    setSelectedPolicies(prev => {
      prev[index] = [...prev[index], item]
      return [...prev]
    })

  const removePolicy = item =>
    setSelectedPolicies(prev => {
      prev[index] = prev[index].filter(s => s.key !== item.key)
      return [...prev]
    })

  const clearPolicies = () =>
    setSelectedPolicies(prev => {
      prev[index] = []
      return [...prev]
    })

  return (
    <Container>
      <TypeaheadLabel>
        Policy combination {index + 1}
        <TypeaheadControl
          value={''}
          placeholder={
            selectedPolicies[index].length < 3
              ? 'Add a policy'
              : 'Remove a policy to select another'
          }
          disabled={selectedPolicies[index].length >= 3}
          items={typeaheadPolicies}
          onChange={addPolicy}
          renderItem={item => <TypeaheadResult>{item.label}</TypeaheadResult>}
        />
      </TypeaheadLabel>
      <SelectedFilters
        hide={selectedPolicies.every(a => a.length === 0)}
        type={'policies'}
        items={selectedPolicies[index]}
        onClick={removePolicy}
        onClear={clearPolicies}
      />
    </Container>
  )
}

export default PolicySelector
