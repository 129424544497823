import React from 'react'
import styled from 'styled-components'
import Patterns from '../ComparisonChart/Patterns'

const KeyRect = styled.rect`
  fill: ${props =>
    ({
      0: props.theme.colorVeryFew,
      0.25: `url(#pattern_0)`,
      0.75: `url(#pattern_1)`,
      1: props.theme.colorMost,
    }[props.status])};
  stroke-width: 0.25;
  stroke: ${props => props.theme.colorMiddleGray};
`
const Svg = styled.svg`
  margin-right: 15px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`

const Key = ({ status }) => (
  <Svg viewBox="0 0 10 10">
    {status === null && (
      <defs>
        <Patterns />
      </defs>
    )}
    <KeyRect width="10" height="10" status={status} />
  </Svg>
)

export default Key
