import React from 'react'
import styled from 'styled-components'
import InfoBubble from '../InfoBubble/InfoBubble'

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
`
const LabelText = styled.span`
  display: block;
  margin-left: 0.5em;
  font-weight: normal;
  font-size: 16px;
`
const RadioLabel = styled.label`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
`
const Emph = styled.span`
  font-style: italic;
  font-weight: 700;
`

const StatusSelector = ({ setAdoptionCondition }) => (
  <RadioContainer onChange={e => setAdoptionCondition(e.target.value)}>
    <p style={{ marginBottom: 5 }}>
      <strong style={{ fontSize: 16 }}>Policy adoption status</strong>
    </p>
    <RadioLabel>
      <input name="status" type="radio" value="adopted" defaultChecked />
      <LabelText>
        Adopted&nbsp;
        <InfoBubble>
          Includes countries that have fully adopted all selected policies,
          including sub-parts.
        </InfoBubble>
      </LabelText>
    </RadioLabel>
    <RadioLabel>
      <input name="status" type="radio" value="adoptedAndPartial" />
      <LabelText>
        Adopted <Emph>and</Emph> partially adopted&nbsp;
        <InfoBubble>
          Includes countries that have either fully adopted selected policies,
          and any country that may have also partially adopted the selected
          policies.
        </InfoBubble>
      </LabelText>
    </RadioLabel>
    <RadioLabel>
      <input name="status" type="radio" value="partialAndNot" />
      <LabelText>
        Partially adopted <Emph>and</Emph> not adopted&nbsp;
        <InfoBubble>
          Includes countries that have not adopted any of the selected policies,
          including sub-parts.
        </InfoBubble>
      </LabelText>
    </RadioLabel>
    <RadioLabel>
      <input name="status" type="radio" value="not" />
      <LabelText>
        Not adopted&nbsp;
        <InfoBubble>
          Includes countries that have not adopted selected policies, and any
          country that may have also partially adopted the selected policies.
        </InfoBubble>
      </LabelText>
    </RadioLabel>
  </RadioContainer>
)

export default StatusSelector
