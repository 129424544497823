import React from 'react'
import styled from 'styled-components'

import Key from './Key'
import InfoBubble from '../../InfoBubble/InfoBubble'

const LegendFrame = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-top: 2rem;
  width: 22em;
`
const Entry = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`
const Label = styled.div`
  font-size: 16px;
  line-height: 1.3;
`

const Legend = () => (
  <LegendFrame>
    <p style={{ marginBottom: 5 }}>
      <strong style={{ fontSize: 16 }}>Legend</strong>
    </p>
    <Entry>
      <Key status={1} />
      <Label>
        Policies adopted&nbsp;
        <InfoBubble>
          Includes countries that have fully adopted all selected policies,
          including sub-parts.
        </InfoBubble>
      </Label>
    </Entry>
    <Entry>
      <Key status={0.75} />
      <Label>
        Policies partially or fully adopted&nbsp;
        <InfoBubble>
          Includes countries that have either fully adopted selected policies,
          and any country that may have also partially adopted the selected
          policies.
        </InfoBubble>
      </Label>
    </Entry>
    <Entry>
      <Key status={0} />
      <Label>
        Policies not adopted&nbsp;
        <InfoBubble>
          Includes countries that have not adopted any of the selected policies,
          including sub-parts.
        </InfoBubble>
      </Label>
    </Entry>
    <Entry>
      <Key status={0.25} />
      <Label>
        Policies partially or not adopted&nbsp;
        <InfoBubble>
          Includes countries that have not adopted selected policies, and any
          country that may have also partially adopted the selected policies.
        </InfoBubble>
      </Label>
    </Entry>
  </LegendFrame>
)

export default Legend
