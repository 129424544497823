import React, { useContext } from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'
import LazyTippy from '../../policypage/LazyTippy'

import PolicyAbbr from '../../policypage/PolicyAbbr'
import AdoptionStatusBlock from '../../policypage/AdoptionStatusBlock'
import AdoptionStatusTerm from '../../policypage/AdoptionStatusTerm'
import Flag from '../../policypage/Flag'

import { ComparisonContext } from '../ComparisonTool'

const StyledTippy = styled(LazyTippy)`
  min-width: 20rem;
`
const Header = styled.header`
  background: ${props => props.theme.colorEvenLighterGray};
  padding: 15px;
  padding-top: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 16px;
  font-weight: 800;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
`
const Row = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const CountryList = styled.section`
  max-height: 19em;
  overflow-y: scroll;
  padding: 15px;
  padding-top: 0;
`
const StatusBlock = styled(AdoptionStatusBlock)`
  font-size: 14px;
  box-shadow: none;
`
const Abbr = styled(PolicyAbbr)`
  font-size: 18px;
`
const StyledFlag = styled(Flag)`
  width: 3em;
  margin-right: 15px;
`
const CountryLink = styled(Link)`
  font-weight: 800;
  display: flex;
  align-items: center;
`
const StyledStatusBlock = styled(StatusBlock)`
  margin-top: 15px;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
`

const TooltipContent = ({ bar }) => {
  const uniqueCountries = bar.layers.map((layer, index) => ({
    ...layer,
    countries: bar.layers[index + 1]
      ? Object.values(layer.countries).filter(
          country =>
            !Object.keys(bar.layers[index + 1].countries).includes(
              country.place_id
            )
        )
      : Object.values(layer.countries),
  }))

  return (
    <>
      <Header>
        <Row>
          {bar.names.map(name => (
            <Abbr key={name}>{name.split(' - ')[0]}</Abbr>
          ))}
        </Row>
      </Header>
      <CountryList>
        {uniqueCountries.map(({ status, countries }) => (
          <section key={status}>
            <StyledStatusBlock status={status}>
              {countries.length}{' '}
              <AdoptionStatusTerm uppercase status={status} />
            </StyledStatusBlock>
            {countries
              .sort((a, b) => a.place_name.localeCompare(b.place_name))
              .map(country => (
                <CountryLink
                  key={country.place_id}
                  to={`/${country.place_id.toLowerCase()}`}
                >
                  <StyledFlag iso2={country.place_id} />
                  {country.place_name}
                </CountryLink>
              ))}
          </section>
        ))}
      </CountryList>
    </>
  )
}

const Tooltip = ({ index, bar, children }) => {
  let tooltipParent = 'parent'
  if (typeof document !== 'undefined') {
    tooltipParent = document.body
  }

  return (
    <StyledTippy
      theme={'light'}
      appendTo={tooltipParent}
      interactive={true}
      interactiveBorder={10}
      content={<TooltipContent {...{ index, bar }} />}
      trigger="click"
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              // the 10 accounts for the height of the little arrow
              offset: ({ reference }) => [0, 10 - reference.height / 2],
            },
          },
        ],
      }}
    >
      {children}
    </StyledTippy>
  )
}

export default Tooltip
